import Swiper from 'swiper';
import '@css/app.css'
import 'swiper/css';
import {Autoplay} from "swiper/modules";
import {quicklinks} from "./quicklinks.ts";

document.querySelectorAll(".switch-theme").forEach((element) => {
    element.addEventListener('click', () => {
        let theme = localStorage.theme

        if (theme === 'dark') {
            localStorage.theme = 'light'
        } else {
            localStorage.theme = 'dark'
        }

        document.documentElement.classList.toggle('dark')
    });
});

document.querySelector("#menu-toggle")?.addEventListener('click', () => {
    document.querySelector("#primary-navigation")?.classList.toggle('menu-active');
});


new Swiper(
    '.swiper-container',
    {
        slidesPerView: 2,
        spaceBetween: 20,
        breakpoints: {
            480: {
                slidesPerView: 3,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 4,
                spaceBetween: 40,
            },
            1024: {
                slidesPerView: 4,
                spaceBetween: 50,
            },
            1280: {
                slidesPerView: 5,
                spaceBetween: 50,
            }
        },
        loop: true,
        modules: [Autoplay],
        autoplay: {
            delay: 0,
            disableOnInteraction: true,
            pauseOnMouseEnter: true,
        },
        speed: 8000,
    }
);

quicklinks();

document.querySelectorAll('.navigation-fly-out').forEach((flyout) => {
    const button = flyout.querySelector('button');
    button?.addEventListener('click', (e) => {
        e.preventDefault();
        button.setAttribute(
            'aria-expanded',
            `${(button.getAttribute('aria-expanded') !== 'true').toString()}`
        );
        flyout.classList.toggle('active');
    });
});

document.querySelectorAll('.mobile-menu-button').forEach((mobileMenu) => {
    mobileMenu?.addEventListener('click', () => {
        document.querySelector('body')?.classList.toggle('mobile-menu-active');
    });
});


document.querySelectorAll('button[aria-expanded]').forEach((button) => {
    button?.addEventListener('click', () => {
        let status = button.getAttribute('aria-expanded');
        let controls = button.getAttribute('aria-controls');

        if (controls === null) {
            return;
        }

        const target = document.getElementById(controls);

        if (target === null) {
            return;
        }

        button.setAttribute(
            'aria-expanded',
            `${(status !== 'true').toString()}`
        );

        target.classList.toggle('active');
    });
});
