import { gsap, ScrollTrigger } from 'gsap/all';
// import { setCSSVariable } from '../lib/css-variables';

gsap.registerPlugin(ScrollTrigger);

export function quicklinks() {
    const quickLinksList = document.getElementById('side-menu') as HTMLElement;
    if (!quickLinksList) {
        return false;
    }
    const quickLinks = Array.from<HTMLElement>(quickLinksList.querySelectorAll('li'));

    const animateActive = (item: HTMLElement) => {
        quickLinks.forEach((li) => li.classList.toggle('active', li === item));
    };

    const contentElements = quickLinks.map((item) => document.getElementById(item.dataset.anchor!));
    animateActive(document.querySelector(`[data-anchor="${contentElements[0]?.getAttribute('id')}"]`) as HTMLElement);

    let colors = ["blue", "red", "white", "yellow", "purple", "orange", "cyan"];

    contentElements.forEach((item, index) => {
        if (item) {
            const next = contentElements[index + 1];
            const end = next ? next.getBoundingClientRect().top: item.parentElement?.getBoundingClientRect().bottom;

            if (end) {
                ScrollTrigger.create({
                    trigger: item,
                    start: 'top 150px',
                    end: `+=${end - item.getBoundingClientRect().top}`,
                    onEnter: () => {
                        animateActive(document.querySelector(`[data-anchor="${item.getAttribute('id')}"]`) as HTMLElement);
                    },
                    onEnterBack: () => {
                        animateActive(document.querySelector(`[data-anchor="${item.getAttribute('id')}"]`) as HTMLElement);
                    },
                    // markers: {startColor: color, endColor: color    , fontSize: "18px", fontWeight: "bold", indent: 20}
                });
            }
        }
    });
}
